import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import image1 from '../../../assets/images/albums/installations/early-projects/ghost-of-the-seas-ii.jpg'
import image2 from '../../../assets/images/albums/installations/early-projects/ghost-of-the-seas-ii_top-view.jpg'
import image3 from '../../../assets/images/albums/installations/early-projects/ghost-of-the-seas-ii_in-display.jpg'
import image4 from '../../../assets/images/albums/installations/early-projects/ghost-of-the-seas-ii_closeup.jpg'
import image5 from '../../../assets/images/albums/installations/early-projects/coral-reef-awareness-i.jpg'
import image6 from '../../../assets/images/albums/installations/early-projects/coral-reef-awareness-ii.jpg'
import image7 from '../../../assets/images/albums/installations/early-projects/coral-reef-awareness-iii.jpg'
import image8 from '../../../assets/images/albums/installations/early-projects/displaced-and-tangled_featured-artist-installation-made-in-fort-point-boston.jpg'
import image9 from '../../../assets/images/albums/installations/early-projects/solo-exhibition-argentinian-embassy-berlin.jpg'
import image10 from '../../../assets/images/albums/installations/early-projects/flower-blanket-farewell-to-summer.jpg'
import image11 from '../../../assets/images/albums/installations/early-projects/more-green-space_the-power-of-verbal-art-project-installation.jpg'
import image12 from '../../../assets/images/albums/installations/early-projects/an-abstract-poem-of-freedom_2009.jpg'
import image13 from '../../../assets/images/albums/installations/early-projects/an-abstract-poem-of-freedom_detail1-2009.jpg'
import image14 from '../../../assets/images/albums/installations/early-projects/an-abstract-poem-of-freedom_detail2-2009.jpg'
import image15 from '../../../assets/images/albums/installations/early-projects/an-abstract-poem-of-freedom-with-soldiers_2009.jpg'
import image16 from '../../../assets/images/albums/installations/early-projects/reinventing-nature-2012.jpg'
import image17 from '../../../assets/images/albums/installations/early-projects/reinventing-nature_detail1-2012.jpg'
import image18 from '../../../assets/images/albums/installations/early-projects/oid-el-ruido-de-rotas-cadenas_2010.jpg'
import image19 from '../../../assets/images/albums/installations/early-projects/oid-el-ruido-de-rotas-cadenas_detail1-2010.jpg'
import image20 from '../../../assets/images/albums/installations/early-projects/revolution-accomplished_700px.jpg'
import image21 from '../../../assets/images/albums/installations/early-projects/revolution-accomplished-detail1_700px.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: image1,
    width: 540,
    height: 960,
    caption: 'Ghost of the Seas - Ceramic and mixed media - Fondation François Schneider Centre d\'Art Contemporains - France 2017'
  },
  {
    src: image2,
    width: 960,
    height: 530,
    caption: 'Ghost of the Seas - Top View - Fondation François Schneider Centre d\'Art Contemporains - France 2017'
  },
  {
    src: image3,
    width: 540,
    height: 960,
    caption: 'Ghost of the Seas II - Ceramic and mixed media - Humboldt-Universität zu Berlin - Thaer-Institut - Berlin Science Week 2018'
  },
  {
    src: image4,
    width: 540,
    height: 960,
    caption: 'Ghost of the Seas II - Detail - Humboldt-Universität zu Berlin - Thaer-Institut - Berlin Science Week 2018'
  },
  {
    src: image5,
    width: 960,
    height: 607,
    caption: 'Coral Reef Awareness I - corals and handmade ceramics - artist studio - Berlin 2017'
  },
  {
    src: image6,
    width: 960,
    height: 540,
    caption: 'Coral Reef Awareness II - corals and handmade ceramics - artist studio - Berlin 2017'
  },
  {
    src: image7,
    width: 960,
    height: 647,
    caption: 'Coral Reef Awareness III - corals and handmade ceramics - artist studio - Berlin 2017'
  },
  {
    src: image8,
    width: 960,
    height: 300,
    caption: 'Displaced and Tangled - featured artist Made in Fort Point - Boston 2015'
  },
  {
    src: image9,
    width: 960,
    height: 540,
    caption: 'Solo Exhibition - Embassy of Argentina, Berlin - 2017'
  },
  {
    src: image10,
    width: 960,
    height: 720,
    caption: 'Flower Blanket, the Farewell to Summer - temporary public art - Boston 2014 (New England Foundation for the Arts Grant)'
  },
  {
    src: image11,
    width: 720,
    height: 960,
    caption: 'More Green Space - Midway Artists Studios Open Studios - Boston 2012'
  },
  {
    src: image12,
    width: 642,
    height: 960,
    caption: 'An Abstract Poem of Freedom - ceramic & mixed media - 3\'Hx7\'Lx2\'W'
  },
  {
    src: image13,
    width: 960,
    height: 642,
    caption: 'An Abstract Poem of Freedom (detail 1) - ceramic & mixed media - 3\'Hx7\'Lx2\'W'
  },
  {
    src: image14,
    width: 960,
    height: 488,
    caption: 'An Abstract Poem of Freedom (detail 2) - ceramic & mixed media - 3\'Hx7\'Lx2\'W'
  },
  {
    src: image15,
    width: 642,
    height: 960,
    caption: 'An Abstract Poem of Freedom (signing) - ceramic & mixed media - 3\'Hx7\'Lx2\'W'
  },
  {
    src: image16,
    width: 809,
    height: 960,
    caption: 'Reinventing Nature - acrylic on canvas & mixed media - 62.5"x80.5"'
  },
  {
    src: image17,
    width: 638,
    height: 960,
    caption: 'Reinventing Nature (detail) - acrylic on canvas & mixed media - 62.5"x80.5"'
  },
  {
    src: image18,
    width: 541,
    height: 960,
    caption: 'Oíd el ruido de rotas cadenas - handmade ceramic and cotton canvas chains, wood, iron, mirror - 15\'x56" - Biennial of the Americas - Museo de las Américas - Denver'
  },
  {
    src: image19,
    width: 960,
    height: 540,
    caption: 'Oíd el ruido de rotas cadenas (detail) - handmade ceramic and cotton canvas chains, wood, iron, mirror - 15\'x56"'
  },
  {
    src: image20,
    width: 960,
    height: 639,
    caption: 'Revolution Accomplished - handmade ceramic and cotton chains, wood - 8\'x9\''
  },
  {
    src: image21,
    width: 960,
    height: 638,
    caption: 'Revolution Accomplished (detail) - handmade ceramic and cotton chains, wood - 8\' x 9\''
  }
]
